.notification {
  position: fixed;
  top: 20px;
  right: -200px;
  min-width: 200px;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.3s forwards;
}

.notification__text {
  margin: 0;
}

.notification-info {
  background-color: #2196f3;
  color: white;
}

.notification-success {
  background-color: #4caf50;
  color: white;
}

.notification-error {
  background-color: #f44336;
  color: white;
}

@keyframes slideIn {
  to {
    right: 20px;
  }
}
